@tailwind base;
@tailwind components;
@tailwind utilities;

.carousel-open:checked + .carousel-item {
  position: static;
  opacity: 100;
}

.carousel-item {
  -webkit-transition: opacity 0.6s ease-out;
  transition: opacity 0.6s ease-out;
}

#carousel-1:checked ~ .control-1 ,
#carousel-2:checked ~ .control-2,
#carousel-3:checked ~ .control-3 ,
#carousel-4:checked ~ .control-4,
#carousel-5:checked ~ .control-5,
#carousel-6:checked ~ .control-6,
#carousel-7:checked ~ .control-7,
#carousel-8:checked ~ .control-8,
#carousel-9:checked ~ .control-9,
#carousel-10:checked ~ .control-10,
#carousel-11:checked ~ .control-11,
#carousel-12:checked ~ .control-12,
#carousel-13:checked ~ .control-13,
#carousel-14:checked ~ .control-14,
#carousel-15:checked ~ .control-15 {
  display: block;
}
.carousel-indicators {
  list-style: none;
  margin: 0;
  padding: 0;
  position: absolute;
  top: 1%;
  left: 0;
  right: 0;
  text-align: center;
  z-index: 10;
}
#carousel-1:checked
  ~ .control-1
  ~ .carousel-indicators
  li:nth-child(1)
  .carousel-bullet,
#carousel-2:checked
  ~ .control-2
  ~ .carousel-indicators
  li:nth-child(2)
  .carousel-bullet,
#carousel-3:checked
  ~ .control-3
  ~ .carousel-indicators
  li:nth-child(3)
  .carousel-bullet,  
  #carousel-4:checked
  ~ .control-4
  ~ .carousel-indicators
  li:nth-child(4)
  .carousel-bullet,  
  #carousel-5:checked
  ~ .control-5
  ~ .carousel-indicators
  li:nth-child(5)
  .carousel-bullet,  
  #carousel-6:checked
  ~ .control-6
  ~ .carousel-indicators
  li:nth-child(6)
  .carousel-bullet,  
  #carousel-7:checked
  ~ .control-7
  ~ .carousel-indicators
  li:nth-child(7)
  .carousel-bullet,  
  #carousel-8:checked
  ~ .control-8
  ~ .carousel-indicators
  li:nth-child(8)
  .carousel-bullet,  
  #carousel-9:checked
  ~ .control-9
  ~ .carousel-indicators
  li:nth-child(9)
  .carousel-bullet,  
  #carousel-10:checked
  ~ .control-10
  ~ .carousel-indicators
  li:nth-child(10)
  .carousel-bullet,  
  #carousel-11:checked
  ~ .control-11
  ~ .carousel-indicators
  li:nth-child(11)
  .carousel-bullet,  
  #carousel-12:checked
  ~ .control-12
  ~ .carousel-indicators
  li:nth-child(12)
  .carousel-bullet,  
  #carousel-13:checked
  ~ .control-13
  ~ .carousel-indicators
  li:nth-child(13)
  .carousel-bullet,  
  #carousel-14:checked
  ~ .control-14
  ~ .carousel-indicators
  li:nth-child(14)
  .carousel-bullet,  


  #carousel-15:checked
  ~ .control-15
  ~ .carousel-indicators
  li:nth-child(15)
  .carousel-bullet {
  color: #2b6cb0; /*Set to match the Tailwind colour you want the active one to be */
}

a.text_link:link {color:#0000ff;text-decoration:underline;}
a.text_link:visited {color:#0000ff;
  text-decoration:underline;}
a.text_link:hover {color:#2b6cb0;}